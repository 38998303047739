import Vue from "vue";
import VueRouter from "vue-router";
import UserList from "@/modules/user/views/UserList";
import Login from "@/modules/auth/views/Login";
import authStore from "@/modules/auth/store";
import UserEditForm from "@/modules/user/views/UserEditForm";

Vue.use(VueRouter);

const routes = [
    {path: "/login", name: "login", component: Login, meta: {allowsGuests: true}},
    {path: "/user/:id/edit", name: "editUser", component: UserEditForm, props: true},
    {path: "*", component: UserList}
];

const router = new VueRouter({
    mode: "history",
    routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.allowsGuests)) {
        next();
    }
    if (authStore.state.loggedIn === false || authStore.state.loggedIn === undefined) {
        next("login")
    }

    next();
});

export default router;
