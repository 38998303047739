export default class AuthApi {
    constructor(auth) {
        this.auth = auth;
    }

    async login (email, password) {
        return this.auth.signInWithEmailAndPassword(email, password);
    }

    async logout () {
        return this.auth.signOut();
    }
}
