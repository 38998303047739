import Vue from "vue";
import Vuex from "vuex";
import authStore from "@/modules/auth/store";
import userStore from "@/modules/user/store";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        authStore,
        userStore
    }
});
