export default class UserFilter {
    constructor(users) {
        this.users = users;
    }

    apply(searchTerm) {
        searchTerm = searchTerm.trim();
        if (searchTerm === "") {
            return this.users;
        }

        return this.users.filter(user => this._userMatchesSearchTerm(searchTerm, user));
    }

    _userMatchesSearchTerm (searchTerm, user) {
        return this._matchingEmailAddress(searchTerm, user) || this._matchingFarmer(searchTerm, user);
    }

    _matchingEmailAddress (searchTerm, user) {
        return user.email.includes(searchTerm);
    }

    _matchingFarmer (searchTerm, user) {
        return user.farmer && user.farmer.includes(searchTerm);
    }
}
