import firebase from "firebase/app";
import UserApi from "@/modules/user/api";
import Vue from "vue";

const userApi = new UserApi(firebase.functions());

export default {
    state: {
        users: null,
        usersAreLoading: false,
        userIsUpdating: false,
    },
    getters: {
        users (state) {
            return state.users === null
                ? []
                : state.users;
        },
        usersAreLoading (state) {
            return state.usersAreLoading;
        },
        userIsUpdating (state) {
            return state.userIsUpdating;
        },
        farmers (state) {
            const farms = Object
                .values(state.users)
                .map(u => u.farmer)
                .filter(f => f != null && f.trim() !== "");

            return [...new Set(farms)];
        }
    },
    mutations: {
        setUsers (state, users) {
            state.users = users;
        },
        deleteUser (state, user) {
            Vue.delete(state.users, user.uid);
        },
        updateUser (state, user) {
            state.users[user.uid] = user;
        },
        toggleUserLoading (state) {
            state.usersAreLoading = !state.usersAreLoading;
        },
        toggleUserUpdating (state) {
            state.userIsUpdating = !state.userIsUpdating;
        }
    },
    actions: {
        async fetchUsers ({commit, state}, forceRefresh) {
            if (state.users === null || forceRefresh) {
                commit("toggleUserLoading");
                commit("setUsers", await userApi.getUsers());
                commit("toggleUserLoading");
            }
        },
        async updateUser ({commit}, user) {
            commit("toggleUserUpdating");
            commit("updateUser", await userApi.updateUser(user));
            commit("toggleUserUpdating");
        },
        async deleteUser ({commit}, user) {
            await userApi.deleteUser(user);
            commit("deleteUser", user);
        }
    }
};
