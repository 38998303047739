import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyBjQy3rBv7JTTmtRVzsrqaN3WCcbdDrDqQ",
    authDomain: "bagawk-pu73.firebaseapp.com",
    databaseURL: "https://bagawk-pu73-b14b5.europe-west1.firebasedatabase.app",
    projectId: "bagawk-pu73",
    storageBucket: "bagawk-pu73.appspot.com",
    messagingSenderId: "34065732623",
    appId: "1:34065732623:web:7a51aa53c18acfdf367f7a"
};
firebase.initializeApp(firebaseConfig);
