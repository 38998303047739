import Vue from "vue"
import App from "./App.vue"
import "./firebase";
import store from "./store";
import router from "./routes";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";
import "@/assets/css/theme.css";
import "@/assets/fonts/feather/feather.css";


Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  store,
  router
}).$mount("#app")
