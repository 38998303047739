<template>
  <nav class="navbar navbar-expand-lg navbar-light" id="topnav">
    <div class="container">
      <ul class="navbar-nav mr-lg-auto">
        <li class="nav-item">
          <a class="nav-link active">
            Benutzer
          </a>
        </li>
      </ul>

      <div class="navbar-user">

        <div class="dropdown">

          <a href="#" class="avatar avatar-sm avatar-online dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img src="./assets/img/avatars/avatar.jpg" alt="..." class="avatar-img rounded-circle">
          </a>

          <div class="dropdown-menu dropdown-menu-right">
            <a href="#" class="dropdown-item" @click="logout">Logout</a>
          </div>

        </div>

      </div>
    </div> <!-- / .container -->
  </nav>
</template>

<script>
import {mapActions} from "vuex";

export default {
  methods: {
    ...mapActions(["logout"]),
  }
}
</script>
