export default class UserApi {
    constructor(functions) {
        this.functions = functions;
    }

    async getUsers() {
        const userResponse = await this.functions.httpsCallable("getUsers")();
        const users = this._sortUsers(userResponse.data);

        return this._userArrayToDictionary(users);
    }

    _sortUsers(users) {
        users.sort((user, other) => user.email.localeCompare(other.email));
        return users;
    }

    _userArrayToDictionary(users) {
        const userDict = {};
        users.forEach(user => userDict[user.uid] = user);

        return userDict;
    }

    async updateUser(user) {
        return this.functions.httpsCallable("editUser")(user);
    }

    async deleteUser(user) {
        return this.functions.httpsCallable("deleteUser")(user);
    }
}
