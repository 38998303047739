<template>
  <div>
    <div class="spinner-border text-primary" role="status" v-if="display === 'inline'">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="d-flex justify-content-center my-5" v-else>
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["display"]
}
</script>
