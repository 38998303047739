import firebase from "firebase/app";
import AuthApi from "@/modules/auth/api";

const authApi = new AuthApi(firebase.auth());

export default {
    state: {
        loggedIn: undefined,
        loginError: "",
        loginIsLoading: false,
    },
    getters: {
        isLoggedIn (state) {
            return state.loggedIn;
        },
        loginError (state) {
            return state.loginError;
        },
        loginIsLoading (state) {
            return state.loginIsLoading;
        }
    },
    mutations: {
        setLoggedIn (state) {
            state.loggedIn = true;
            state.loginIsLoading = false;
        },
        setLoggedOut (state) {
            state.loggedIn = false;
            state.loginIsLoading = false;
        },
        setLoginError (state, error) {
            state.loginError = error;
            state.loginIsLoading = false;
        },
        resetLoginState (state) {
            state.loginError = "";
            state.loggedIn = false;
            state.loginIsLoading = false;
        },
        toggleLoginLoading (state) {
            state.loginIsLoading = !state.loginIsLoading;
        }
    },
    actions: {
        async login ({commit}, payload) {
            commit("resetLoginState");
            commit("toggleLoginLoading");
            try {
                await authApi.login(payload.email, payload.password);
            } catch {
                commit("setLoginError", "Der Login ist fehlgeschlagen. Bitte überprüfe deine E-Mail Adresse und dein Passwort und probiere es erneut.");
            }
        },
        async logout ({commit}) {
            await authApi.logout();
            commit("resetLoginState");
        }
    }
};
