<template>
  <div class="header">
    <div class="container">
      <div class="row row justify-content-center">
        <div class="col-12">

          <div class="header-body">
            <div class="row align-items-end">
              <div class="col">
                <h1 class="header-title">
                  Benutzer
                </h1>
              </div>

              <div class="col-auto">
                <a href="#" class="btn btn-primary lift disabled">
                  Benutzer anlegen
                </a>
              </div>
            </div>
          </div>

          <div class="card mt-5">
            <div class="card-header">
              <div class="col">
                <form>
                  <div class="input-group input-group-flush input-group-merge">
                    <input type="search" class="form-control form-control-prepended list-search" placeholder="Suche"
                           v-model="searchTerm">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <span class="fe fe-search"></span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-auto">
                <button class="btn btn-sm btn-white" @click="fetchUsers(true)"><span class="fe fe-refresh-cw"></span></button>
              </div>
            </div>
            <div>
              <div v-if="usersAreLoading">
                <Spinner></Spinner>
              </div>
              <div class="table-responsive mb-0" v-else>
                <table class="table table-sm table-nowrap card-table">
                  <thead>
                  <tr>
                    <th>
                      <a href="#" class="text-muted">E-Mail</a>
                    </th>
                    <th>
                      <a href="#" class="text-muted">UID</a>
                    </th>
                    <th>
                      <a href="#" class="text-muted">Farmer</a>
                    </th>
                    <th>
                      <a href="#" class="text-muted">Trial?</a>
                    </th>
                    <th>
                      <a href="#" class="text-muted">Administrator</a>
                    </th>
                    <th>
                      <a href="#" class="text-muted">Super-User</a>
                    </th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody class="list">
                  <tr v-for="user in this.filteredUsers" :key="user.uid">
                    <td>
                      {{ user.email }}
                    </td>
                    <td>
                      <span class="text-muted">{{ user.uid }}</span>
                    </td>
                    <td>
                      {{ user.farmer }}
                    </td>
                    <td>
                      <div v-if="customers[user.farmer] !== undefined">
                        <span class="badge badge-danger" v-if="customers[user.farmer].daysLeft < 0">
                          {{ customers[user.farmer].daysLeft }} days
                        </span>
                        <span class="badge badge-warning" v-else-if="customers[user.farmer].daysLeft < 7">
                          {{ customers[user.farmer].daysLeft }} days
                        </span>
                        <span class="badge badge-success" v-else>
                          {{ customers[user.farmer].daysLeft }} days
                        </span>
                      </div>
                    </td>
                    <td>
                        <span class="badge badge-primary" v-if="user.isAdmin">
                          Administrator
                        </span>
                    </td>
                    <td>
                        <span class="badge badge-warning" v-if="user.isSuperUser">
                          Super-User
                        </span>
                    </td>
                    <td class="text-right">
                      <div class="dropdown">
                        <a href="#" class="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown"
                           aria-haspopup="true" aria-expanded="false">
                          <i class="fe fe-more-vertical"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <a href="#" class="dropdown-item" @click="editUser(user)">
                            Bearbeiten
                          </a>
                          <a href="#" class="dropdown-item" @click.prevent="deleteUser(user)">
                            Löschen
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import UserFilter from "@/modules/user/filter";
import Spinner from "@/components/Spinner";
import firebase from "firebase/app";

export default {
  components: {Spinner},
  data() {
    return {
      searchTerm: "",
      customers: {},
    }
  },
  computed: {
    ...mapGetters(["users", "usersAreLoading"]),
    filteredUsers() {
      return new UserFilter(Object.values(this.users)).apply(this.searchTerm);
    }
  },
  methods: {
    ...mapActions(["fetchUsers"]),
    editUser (user) {
      this.$router.push({name: "editUser", params: {id: user.uid}});
    },
    ...mapActions(["deleteUser"]),
  },
  async created() {
    firebase.database().ref("/customers").get().then((snapshot) => {
      let customers = snapshot.val();

      for (const key in customers) {
        if (customers[key].trial === undefined) {
          continue
        }

        this.customers[key] = {
          end: customers[key].end,
          daysLeft: Math.ceil((Date.parse(customers[key].trial.end) - (new Date())) / (1000 * 60 * 60 * 24))
        }
      }
    })

    this.fetchUsers();
  }
}
</script>
