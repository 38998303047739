<template>
  <div id="app">
    <NavBar v-if="$route.name !== 'login'"></NavBar>
    <router-view></router-view>
  </div>
</template>

<script>
import NavBar from "@/NavBar";
import firebase from "firebase/app";
import {mapActions, mapMutations} from "vuex";
import AuthService from "@/modules/auth/auth-service";

export default {
  components: {NavBar},
  methods: {
    ...mapMutations(['setLoggedIn', 'setLoggedOut', 'setLoginError']),
    ...mapActions(["logout"]),
    async handleAuthStateChange (user) {
      if (!user) {
        return this.userIsUnauthenticated();
      }

      if (!await new AuthService().checkUserAuthorization(user)) {
        return this.userIsUnauthorized();
      }

      return this.userIsAuthorized();
    },
    async userIsAuthorized () {
      this.setLoggedIn();
      return this.$router.push("/");
    },
    userIsUnauthorized () {
      this.setLoginError("Der Login ist fehlgeschlagen, bitte wende dich an einen Administrator.");
      this.setLoggedOut();
    },
    async userIsUnauthenticated () {
      this.setLoggedOut();
      if (this.$route.name !== "login") {
        await this.$router.push("/login");
      }
    }
  },
  async created() {
    firebase.auth().onAuthStateChanged(this.handleAuthStateChange);
  }
}
</script>
