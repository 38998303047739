<template>
  <div class="d-flex align-items-center vh-100">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-5 col-xl-4 order-md-1 my-5">
          <Spinner v-if="isLoggedIn === undefined"></Spinner>
          <div v-else>
            <h1 class="display-4 text-center mb-5">
              Bitte einloggen
            </h1>

            <form>
              <div class="form-group">
                <label>Email Adresse</label>
                <input type="email" class="form-control" placeholder="name@address.com" v-model="email">
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <label>Passwort</label>
                  </div>
                </div>

                <div class="input-group input-group-merge">
                  <input type="password" class="form-control form-control-appended"
                         placeholder="Bitte das Passwort eingeben" v-model="password" @keydown.enter="doLogin">

                  <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="fe fe-eye"></i>
                  </span>
                  </div>

                </div>
              </div>

              <div class="alert alert-danger" v-if="loginError !== ''">
                {{ loginError }}
              </div>

              <button class="btn btn-lg btn-block btn-primary mb-3" type="button" :class="{ disabled: loginIsLoading }"
                      v-on:submit.prevent @click="doLogin">
                Einloggen
              </button>

              <div class="text-muted text-center mt-5" v-if="loginIsLoading">
                <Spinner></Spinner>
              </div>
            </form>
          </div>

        </div>
      </div> <!-- / .row -->
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters,} from "vuex";
import Spinner from "@/components/Spinner";

export default {
  components: {Spinner},
  data() {
    return {
      email: "",
      password: ""
    }
  },
  computed: {
    ...mapGetters(["isLoggedIn", "loginError", "loginIsLoading"])
  },
  methods: {
    ...mapActions(["login"]),
    doLogin() {
      this.login({email: this.email, password: this.password});
    }
  }
}
</script>
