<template>
  <div class="container">
    <div class="row row justify-content-center">
      <div class="col-12">

        <div class="header">
          <div class="header-body">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">
                  Benutzer bearbeiten
                </h1>
              </div>
              <div class="col-auto">
                <router-link to="/" class="btn btn-link">zurück zur Benutzerübersicht</router-link>
              </div>
            </div> <!-- / .row -->
          </div>
        </div>

        <form v-on:submit.prevent>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label class="mb-1">
                  Email Adresse
                </label>

                <input type="email" class="form-control" v-model="user.email">
              </div>
            </div>

            <div class="col-12">
              <div class="form-group">
                <label class="mb-1">
                  Farmer:
                </label>

                <select class="custom-select" v-model="user.farmer">
                  <option value=""></option>
                  <option :value="farmer" v-for="(farmer, index) in farmers" :key="`farmer-${index}`">
                    {{farmer}}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <hr class="my-5">

          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="mb-1">
                  Administrator
                </label>
                <small class="form-text text-muted">
                  Ist ein Benutzer ein Administrator, so kann er für seinen Betrieb/Farmer z. B. Zugriffsanfragen
                  annehmen oder ablehnen.
                </small>

                <div class="row">
                  <div class="col-auto">
                    <div class="custom-control custom-switch">
                      <input type="checkbox" class="custom-control-input" v-model="user.isAdmin" id="isAdminCheckbox">
                      <label class="custom-control-label" for="isAdminCheckbox"></label>
                    </div>
                  </div>
                  <div class="col ml-n2">
                    <small class="text-muted">
                      <span v-if="user.isAdmin">Der Benutzer ist ein Administrator.</span>
                      <span v-else>Der Benutzer ist kein Administrator.</span>
                    </small>
                  </div>
                </div> <!-- / .row -->
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="mb-1">
                  Super-User
                </label>
                <small class="form-text text-muted">
                  Ist ein Benutzer ein Super-User, verfügt er über Zugriff auf den PAdmin und kann farmübergreifende
                  Einstellungen vornehmen.
                </small>

                <div class="row">
                  <div class="col-auto">
                    <div class="custom-control custom-switch">
                      <input type="checkbox" class="custom-control-input" v-model="user.isSuperUser" id="isSuperUserCheckbox">
                      <label class="custom-control-label" for="isSuperUserCheckbox"></label>
                    </div>
                  </div>
                  <div class="col ml-n2">
                    <small class="text-muted">
                      <span v-if="user.isSuperUser">Der Benutzer ist ein Super-User.</span>
                      <span v-else>Der Benutzer ist kein Super-User.</span>
                    </small>
                  </div>
                </div> <!-- / .row -->
              </div>
            </div>
          </div>

          <hr class="my-5">

          <div class="row">
            <div class="col">
              <button class="btn btn-primary" :class="{ disabled: userIsUpdating }" @click="this.save">
                Änderungen speichern
              </button>
            </div>
            <div class="col-auto">
              <Spinner display="inline" v-if="userIsUpdating"></Spinner>
            </div>
          </div>
        </form>

      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Spinner from "@/components/Spinner";

export default {
  components: {Spinner},
  props: ["id"],
  data () {
    return {
      user: {
        uid: "",
        email: "",
        farmer: "",
        isAdmin: false,
        isSuperUser: false
      }
    }
  },
  computed: {
    ...mapGetters(["users", "farmers", "userIsUpdating"]),
  },
  mounted () {
    if (!(this.id in this.users)) {
      this.$router.push("/");
    }

    this.user = this.users[this.id];
  },
  methods: {
    ...mapActions(["updateUser"]),
    async save () {
      try {
        await this.updateUser(this.user);
        await this.$router.push("/");
      } catch (error) {
        console.log(error);
      }
    }
  }
}
</script>
